import $ from '../core/Dom';
import gsap from 'gsap';

export default form => {

    const destinationComboBox = form.querySelector('[role="combobox"][aria-controls$="destination-listbox"]');

    const accommodationTypeComboBox = form.querySelector('[role="combobox"][aria-controls$="type-listbox"]');

    const showOrHideAccommodationTypes = () => {
        if (!destinationComboBox || !accommodationTypeComboBox) {
            return;
        }

        const destinationListBox = document.getElementById(destinationComboBox.getAttribute('aria-controls'));
        const selectedDestination = destinationListBox.querySelector('[role="option"][aria-selected="true"]');
        const selectedDestinationAccommodationTypes = selectedDestination ? (selectedDestination.dataset.types || '').split(',').filter(type => !!type) : [];

        const accommodationTypeListBox = document.getElementById(accommodationTypeComboBox.getAttribute('aria-controls'));
        accommodationTypeListBox.querySelectorAll('[role="option"]').forEach(typeOption => {
            const disabled = !!(selectedDestinationAccommodationTypes.length && selectedDestinationAccommodationTypes.indexOf(typeOption.dataset.uid) === -1);
            if (disabled) {
                typeOption.setAttribute('aria-disabled', 'true');
            } else {
                typeOption.removeAttribute('aria-disabled');
            }
        });

        let selectedAccommodationType = accommodationTypeListBox.querySelector('[role="option"][aria-selected="true"]');
        if (selectedAccommodationType && selectedAccommodationType.hasAttribute('aria-disabled')) {
            const accommodationTypeListBoxInstance = $(accommodationTypeComboBox.closest('[data-component="Listbox"]')).data('_listbox');
            if (accommodationTypeListBoxInstance) {
                accommodationTypeListBoxInstance.reset();
            }
            selectedAccommodationType = null;
        }

        if (selectedAccommodationType && selectedDestinationAccommodationTypes.length && selectedDestinationAccommodationTypes.indexOf(selectedAccommodationType.dataset.uid) === -1) {
            const accommodationTypeListBoxInstance = $(accommodationTypeComboBox.closest('[data-component="Listbox"]')).data('_listbox');
            if (accommodationTypeListBoxInstance) {
                accommodationTypeListBoxInstance.reset();
            }
        }

    };

    const validateComboBox = combobox => {
        const { value } = combobox.parentNode.querySelector('input[type="hidden"]');
        const errorDiv = combobox.parentNode.querySelector('[data-error]');
        if (!value) {
            combobox.classList.add('has-errors');
            if (errorDiv && errorDiv.hidden) {
                errorDiv.hidden = false;
                errorDiv.querySelector('span').textContent = errorDiv.dataset.error;
                gsap.timeline()
                    .fromTo(errorDiv, { opacity: 0 }, {
                        opacity: 1,
                        duration: 0.15
                    }, 0)
                    .fromTo(errorDiv, { y: 15 }, {
                        y: 0,
                        duration: 0.3,
                        ease: 'Back.easeOut'
                    }, 0)
                    .set(errorDiv, { clearProps: 'all' });
            }
            gsap.timeline({
                repeat: 2,
                yoyo: true
            })
                .to(combobox, {
                    x: '+=3',
                    duration: 0.1,
                    ease: 'none'
                })
                .to(combobox, {
                    x: '-=3',
                    duration: 0.1,
                    ease: 'none'
                });
        } else {
            combobox.classList.remove('has-errors');
            if (errorDiv) {
                gsap.killTweensOf(errorDiv);
                errorDiv.hidden = true;
                errorDiv.querySelector('span').textContent = '';
            }
        }
    };

    const validate = () => {

        // Get required combo boxes
        form.querySelectorAll('button[role="combobox"][aria-required="true"]').forEach(validateComboBox);
        const firstError = form.querySelector('.has-errors');
        if (firstError) {
            firstError.focus();
            return false;
        }
        return true;
    };

    const onSubmit = e => {
        if (!validate()) {
            e.preventDefault();
        }
    };

    const onComboBoxChange = e => {
        const { currentTarget: combobox } = e;
        if (combobox.classList.contains('has-errors')) {
            validateComboBox(combobox);
        }

        showOrHideAccommodationTypes();
    };

    form.addEventListener('submit', onSubmit);

    form.querySelectorAll('[role="combobox"]').forEach(combobox => {
        combobox.addEventListener('change', onComboBoxChange);
    });

    // Keep date pickers in sync
    const fromDateInput = form.querySelector('input[id$="from"]');

    const onFromDateChange = () => {
        const toDateInput = form.querySelector('input[id$="to"]');
        if (!toDateInput) {
            return;
        }
        const toDatePicker = toDateInput._flatpickr;
        if (!toDatePicker) {
            return;
        }
        const fromDate = fromDateInput.value;
        if (!fromDate) {
            return;
        }
        const toDate = toDateInput.value;
        const minToDate = toDatePicker.parseDate(fromDate).fp_incr(1);
        toDatePicker.set('minDate', minToDate);
        toDatePicker.jumpToDate(minToDate);
        if (!toDate) {
            return;
        }
        if (toDatePicker.parseDate(fromDate).getTime() >= toDatePicker.parseDate(toDate).getTime()) {
            toDatePicker.clear();
            toDatePicker.redraw();
            toDatePicker.jumpToDate(minToDate);
        }
    };

    if (fromDateInput) {
        fromDateInput.addEventListener('change', onFromDateChange);
    }

    setTimeout(() => {
        //showOrHideAccommodationTypes();
    }, 0);

    return {
        destroy() {
            form.removeEventListener('submit', onSubmit);
            form.querySelectorAll('[role="combobox"]').forEach(combobox => {
                combobox.removeEventListener('change', onComboBoxChange);
            });
            if (fromDateInput) {
                fromDateInput.removeEventListener('change', onFromDateChange);
            }
        }
    };

};
