import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

const ListBox = el => {
    const comboBtn = el.querySelector('[role="combobox"]');
    const listbox = document.getElementById(comboBtn.getAttribute('aria-controls'));
    const options = Array.from(listbox.querySelectorAll('[role="option"]'));
    const input = el.querySelector('input[type="hidden"]');

    let tl;
    let isExpanded = false;
    let isFixed;

    const getActiveOption = () => {
        const activeOptionId = comboBtn.getAttribute('aria-activedescendant');
        if (!activeOptionId) {
            return null;
        }
        return listbox.querySelector(`#${activeOptionId}`);
    };

    const scrollToOption = option => {
        if (option) {
            const { height: listboxHeight } = listbox.firstElementChild.getBoundingClientRect();
            const { height: optionHeight } = option.getBoundingClientRect();
            listbox.firstElementChild.scrollTop = Math.max(0, option.offsetTop - (listboxHeight - (optionHeight + 4)));
        } else {
            listbox.firstElementChild.scrollTop = 0;
        }
    };

    const positionListbox = () => {
        gsap.set(listbox, { clearProps: 'top,bottom' });
        if (isFixed || !isExpanded) {
            return;
        }
        const { top } = listbox.getBoundingClientRect();
        const { height: menuHeight } = listbox.firstElementChild.getBoundingClientRect();
        const { height: viewHeight } = Viewport;
        if (top >= (viewHeight - (menuHeight + 20)) && (top - (menuHeight + 20)) > 140) {
            gsap.set(listbox, {
                top: 'auto',
                bottom: '100%'
            });
        }
    };

    const expand = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        comboBtn.setAttribute('aria-expanded', 'true');
        listbox.hidden = false;
        comboBtn.focus();
        if (tl) {
            tl.kill();
            gsap.killTweensOf(listbox);
        }
        positionListbox();
        scrollToOption(getActiveOption());
        tl = gsap.timeline({
                onComplete() {
                    tl = null;
                }
            })
            .fromTo(listbox.firstElementChild, { y: isFixed ? 10 : -10 }, {
                y: 0,
                duration: 0.3,
                ease: 'Back.easeOut'
            }, 0)
            .fromTo(listbox.firstElementChild, { opacity: 0 }, {
                opacity: 1,
                duration: 0.15
            }, 0);
    };

    const collapse = (tween = true) => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        comboBtn.setAttribute('aria-expanded', 'false');
        const focusedElement = document.activeElement || null;
        if (listbox.contains(focusedElement)) {
            comboBtn.focus();
        }
        if (tl) {
            tl.kill();
            gsap.killTweensOf(listbox);
        }
        const afterClose = () => {
            listbox.scrollTop = 0;
            listbox.hidden = true;
            gsap.set(listbox, { clearProps: 'top,bottom' });
            tl = null;
        };
        if (!tween) {
            afterClose();
            return;
        }
        tl = gsap.timeline({
                onComplete() {
                    afterClose();
                }
            })
            .to(listbox.firstElementChild, {
                y: isFixed ? 10 : -10,
                duration: 0.3,
                ease: 'Quad.easeIn'
            }, 0)
            .to(listbox.firstElementChild, {
                opacity: 0,
                duration: 0.3
            }, 0);
    };

    const setActiveOption = index => {
        listbox.querySelectorAll('.is-active').forEach(option => option.classList.remove('is-active'));
        const option = options[index] || null;
        if (option) {
            option.classList.add('is-active');
            comboBtn.setAttribute('aria-activedescendant', option.id);
            scrollToOption(option);
        } else {
            comboBtn.removeAttribute('aria-activedescendant');
        }
    };

    const getActiveOptionIndex = () => {
        const activeOption = getActiveOption();
        if (!activeOption) {
            return -1;
        }
        return options.indexOf(activeOption);
    };

    const setNextOptionActive = () => {
        const activeOptionIndex = getActiveOptionIndex();
        let nextActiveIndex;
        if (activeOptionIndex < options.length - 1) {
            nextActiveIndex = activeOptionIndex + 1;
        } else {
            nextActiveIndex = options.length - 1;
        }
        setActiveOption(nextActiveIndex);
    };

    const setPrevOptionActive = () => {
        const activeOptionIndex = getActiveOptionIndex();
        let prevActiveIndex;
        if (activeOptionIndex > 0) {
            prevActiveIndex = activeOptionIndex - 1;
        } else {
            prevActiveIndex = 0;
        }
        setActiveOption(prevActiveIndex);
    };

    const reset = () => {
        listbox.querySelectorAll('[aria-selected="true"]').forEach(activeOption => activeOption.setAttribute('aria-selected', 'false'));
        $(comboBtn).find('span').eq(0).html(comboBtn.dataset.label);
        input.value = '';
        setActiveOption(null);
        collapse();
        el.classList.remove('has-value');
        comboBtn.querySelector('span').setAttribute('aria-hidden', 'true');
        comboBtn.dispatchEvent(new Event('change'));
    };

    const selectOption = option => {
        if (!option || option.hasAttribute('aria-disabled')) {
            return;
        }
        listbox.querySelectorAll('[aria-selected="true"]').forEach(activeOption => activeOption.setAttribute('aria-selected', 'false'));
        $(comboBtn).find('span').eq(0).html(option.dataset.label);
        option.setAttribute('aria-selected', 'true');
        input.value = option.dataset.value;
        setActiveOption(options.indexOf(option));
        collapse();
        if (input.value) {
            el.classList.add('has-value');
            comboBtn.querySelector('span').removeAttribute('aria-hidden');
        } else {
            el.classList.remove('has-value');
            comboBtn.querySelector('span').setAttribute('aria-hidden', 'true');
        }
        comboBtn.dispatchEvent(new Event('change'));
    };

    const toggle = () => {
        if (isExpanded) {
            collapse();
        } else {
            expand();
        }
    };

    const onToggleKeyDown = e => {
        const key = e.key || e.code || e.which || e.keyCode;
        if (key === 'ArrowDown' || key === 40 || key === 'ArrowUp' || key === 38) {
            e.preventDefault();
            e.stopPropagation();
            document.documentElement.classList.remove('no-outline');
            document.documentElement.classList.add('outline');
        } else if (isExpanded && (key === 'Enter' || key === 13 || key === ' ' || key === 'Space' || key === 32)) {
            e.preventDefault();
            e.stopPropagation();
            selectOption(getActiveOption());
        }
    };

    const onToggleKeyUp = e => {
        const key = e.key || e.which || e.keyCode;
        if (key === 'ArrowDown' || key === 40) {
            e.preventDefault();
            if (isExpanded || getActiveOptionIndex() === -1) {
                setNextOptionActive();
            }
            expand();
        } else if (key === 'ArrowUp' || key === 38) {
            e.preventDefault();
            if (isExpanded || getActiveOptionIndex() === -1) {
                setPrevOptionActive();
            }
            expand();
        }
    };

    const onOptionClick = e => {
        selectOption(e.currentTarget);
    };

    $(comboBtn).on('click', toggle);
    $(comboBtn)
        .on('focusin', () => {
            if (document.documentElement.classList.contains('outline')) {
                expand();
            }
        });
    $(comboBtn).on('keydown', onToggleKeyDown);
    $(comboBtn).on('keyup', onToggleKeyUp);

    $(options).on('click', onOptionClick);

    const onBodyClick = e => {
        if (!isExpanded || e.target === comboBtn || comboBtn.contains(e.target) || e.target === listbox || listbox.contains(e.target)) {
            return;
        }
        selectOption(getActiveOption());
        collapse();
    };

    const onBodyKeyUp = e => {
        if (!isExpanded || e.key !== 'Escape') {
            return;
        }
        collapse();
    };

    const $body = $('body');
    $body.on('click focusin', onBodyClick);
    $body.on('keyup', onBodyKeyUp);

    let observer = new IntersectionObserver(([{ isIntersecting }]) => {
        if (isExpanded && !isIntersecting) {
            collapse();
        }
    });
    observer.observe(comboBtn);

    const onBreakpoint = () => {
        const wasFixed = isFixed;
        isFixed = window.getComputedStyle(listbox).position === 'fixed';
        if (isFixed === wasFixed) {
            return;
        }
        if (isFixed && !wasFixed) {
            document.body.appendChild(listbox);
        } else if (!isFixed && wasFixed) {
            comboBtn.parentNode.insertBefore(listbox, comboBtn.nextSibling);
        }
        positionListbox();
    };

    Viewport.on('breakpoint', onBreakpoint);

    const onResize = () => {
        if (!isExpanded) {
            return;
        }
        positionListbox();
    };

    Viewport.on('resize', onResize);

    onBreakpoint();

    return {
        selectOption,
        reset,
        destroy() {
            Viewport.off('breakpoint', onBreakpoint);
            Viewport.off('onResize', onResize);
            $(comboBtn).off('click keyup focusin');
            $(options).off('click');
            $body.off('click focusin', onBodyClick);
            $body.off('keyup', onBodyKeyUp);
            observer.disconnect();
            observer = null;
            collapse(false);
            if (isFixed) {
                document.body.removeChild(listbox);
            }
        }
    };
};

export default el => {
    let listBox;

    const init = () => {
        listBox = ListBox(el);
        $(el).data('_listbox', listBox);
    };

    const destroy = () => {
        listBox.destroy();
        $(el).data('_listbox', null);
    };

    return {
        init,
        destroy
    };
};
